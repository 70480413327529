/* .link{
    background-color: inherit;
    color: #2196f3;
    cursor: pointer;
}

.link:focus{
    background-color: #2196f3;
    color: white;
}

.selected{
    background-color: #2196f3;
    color: white;
} */

.thead {
    background-color: #444;
    color: white;
    font-size: .85em;
    border: 1px #444 solid;
}

th {
    text-align: center;
    line-height: .5em;
    border-radius: 0px;
}

.highlightedTab {
    background-color: #2196f3;
    color: white;
    cursor: default;
}

.invoiceHighlightedTab {
    background-color: rgb(58, 90, 159);
    ;
    color: white;
    cursor: default;
}

.commissionHighlightedTab {
    background-color: rgb(179, 0, 0);
    ;
    color: white;
    cursor: default;
}

.filesHighlightedTab {
    background-color: #33679e;
    color: white;
    cursor: pointer;
}

.inactiveTab {
    background-color: white;
    color: #0f9dff;
    cursor: pointer;
}

.invoiceInactiveTab {
    background-color: white;
    color: rgb(58, 90, 159);
    cursor: pointer;
}

.commissionInactiveTab {
    background-color: white;
    color: rgb(179, 0, 0);
    cursor: pointer;
}

.filesInactiveTab {
    color: black;
    cursor: pointer;
}

.highlightedTab:hover {
    background-color: #2196f3;
    cursor: default;
    cursor: pointer;

}

.invoiceHighlightedTab:hover {
    background-color: rgb(58, 90, 159);
    cursor: default;
    cursor: pointer;

}

.commissionHighlightedTab:hover {
    background-color: rgb(179, 0, 0);
    cursor: default;
    cursor: pointer;
}

.filesHighlightedTab:hover {
    cursor: pointer;
}

.commissionDateAndUsers {
    color: rgb(179, 0, 0);
}

.invoiceDateAndUsers {
    color: rgb(58, 90, 159);
}