.div {
    margin: 0px;
    padding: 0px 12px 0px 0px!important;
    position: relative;
}

.select {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.select:hover {
    border: solid 1px black;
}

.select:focus {
    border: solid 2px #2196f3;
    padding: 0px 0px 0px 4px;
}

.select.err {
    border: 1px solid #f44336;
}

.select.err:focus {
    border: solid 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
    /* border-radius: 10px !important; */
}

.select option {
    font-size: 1rem;
}