.ratesDisplayHeader {
    text-align: center;
}

.tableHeaderLeftAlign > th{
    text-align: left!important;
}

.ratesForm{
    max-width: 55px;
    max-height: 25px;
    background-color: rgb(247, 203, 121);
}

.ratesTable {
    display: inline-block;
    /* overflow: auto; */
}

.ratesTable > thead > tr > th {
    max-height: 10px!important;
    height: 10px!important;
    padding: 0px 10px 0px 0px;
}

.ratesTable > tbody > tr {
    max-height: 30px!important;
    height: 35px!important;
}

.fixedSize > tbody > tr {
    max-height: 30px!important;
    height: 30px!important;
}

.fixedSize > thead > tr > th {
    max-width: 300px!important;
    width: 370px!important;
}

.displayTable > tbody > tr {
    max-height: 30px!important;
    height: 35px!important;
    font-size: 14px!important;
}

.rateTableRow > td {
    max-width: 20px!important;
}

.formDropdown {
    cursor: pointer;
    font-weight: 500;
}

.rateSubscript{
    color: darkgrey
}

.addOriginIcon {
    padding: 3px 0px 0px 0px;
    margin-right: -5px;
}

.originDest-tooltip {
    position: relative;
  }
  
.originDest-tooltip .originDest-tooltiptext {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 15px;
    bottom: 30px;
    z-index: 4000!important;
    border-radius: 15px 15px 15px 0px;
    width: max-content;
    height: 30px;
    font-weight: 400;
    line-height: 10px;
    margin: 0px!important;
}
  
.originDest-tooltip:hover .originDest-tooltiptext {
    visibility: visible;
}
  

@keyframes bounce{
    0% { font-size: 14px; }
    25% { font-size: 16px; color: red;}
    50% { font-size: 18px; }
    75% { font-size: 16px; }
    100% { font-size: 14px; }
}

.specRestSubscript{
    position: absolute;
    top: 0px;
    left: -2px;
    color: red;
    font-size: 14px;
    animation: bounce 2s linear 2s infinite;
}

.contractShipperDropdown{
    background-color: #33679e!important;
    color: orange;
    font-weight: 600;
    margin-left: -10px;
    font: 18px;
    height: 25px;
    margin-top: 5px;
}

.contractShipperDropdownLabel{
    background-color: #33679e!important;
    color: orange;
    margin-left: -10px;
    margin-top: -5px!important;
    margin-bottom: 10px!important;
}

.contractEditInputs {
    margin-left: -10px;
    font: 18px;
    height: 25px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.contractEditCheckBox{
    max-width: 300px;
}

.contractEditInputsLabel{
    background-color: white!important;
    margin-left: -10px;
    height: 12px;
    line-height: 12px;
    margin-top: 10px;
}

.ACTIVE {
    color: green!important;
}

.CLOSED {
    color: grey;
}

.ARCHIVED {
    color: black;
}

.PENDINGREVIEW {
    color: purple;
}

.UPCOMING {
    color: rgb(66, 119, 255);
}

.PENDINGUPCOMING {
    color: purple;
}

.freetime-dot{
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin-bottom: 2px;
    margin-left: -10px!important;
}

.freetime-origin-tooltip {
    position: relative;
    color: red!important;
  }
  
  .freetime-origin-tooltip .freetime-tooltiptext {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
    position: absolute;
    left: 100px;
    bottom: -15px;
    z-index: 4000!important;
    border-radius: 0px 15px 15px 15px;
    width: 250px;
    height: 30px;
    font-weight: 400;
    line-height: 10px;
    margin: 0px!important;
  }
  
  .freetime-origin-tooltip:hover .freetime-tooltiptext {
    visibility: visible;
  }

.freetime-tooltip {
  position: relative;
  color: red!important;
}

.freetime-tooltip .freetime-tooltiptext {
  visibility: hidden;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  padding: 10px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
  position: absolute;
  left: 100px;
  bottom: 20px;
  z-index: 4000!important;
  border-radius: 15px 15px 15px 0px;
  width: 250px;
  height: 30px;
  font-weight: 400;
  line-height: 10px;
  margin: 0px!important;
}

.freetime-tooltip:hover .freetime-tooltiptext {
  visibility: visible;
}

.freightFeesTable {
    font-size: 12px!important;
}

.freightFeesTable > tbody > tr > td {
    line-height: 15px;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}

.freightFeesTable > thead > tr > th {
    padding-bottom: 0px!important;
    padding-top: 0px!important;
    height: 0px!important;
}

.freightFeesRow {
    height: 50px!important;
    padding: 20px!important;
    margin: 0px!important;
    line-height: 2px!important;
}

.removeMargins {
    margin-bottom: 0px;
    padding-top: 30px!important;
}

.demurrageWidth {
    width: 40px!important;
}

.flagButton > button{
    height: 20px;
    width: 20px!important;
}

.flagButtonIcon {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}

.flag-icon {
    border: 1px solid rgb(218, 217, 217);
    height: 16px;
    width: 20px!important;
    margin-top: 4px;
    float: right;
    margin-left: 10px;
}

.editIcons {
    text-align: center;
    padding: 0px!important;
    margin: 0px!important;
}

.saveIcon {
    margin-top: 0px!important;
    font-size:35px;
}

.editAndDeleteIcons{
    display: flex;
    justify-content: space-evenly;
    color: black!important;
}

.modalSaveIcon{
    margin-top: 3px;
    display: flex;
    justify-content: center;
    color: black!important;
}

.manageButtonIcon{
  margin: 0px!important;
  padding: 0px!important;
}

.manageButton{
  padding-left: 3px!important;
  padding-right: 5px!important;
  margin-left: 0px!important;
}

.manageButton > button {
    padding: 3px;
}

.feeAddButton > button {
    margin-top: 2px;
    font-size: 12px;
    height: 19px!important;
}

.smallForm {
    height: 20px!important;
    font-size: 12px;
}

.smallFormLabel {
    font-size: 10px;
    font-weight: 500;
}

.contractStatus.menu-selected {
    background-color: rgb(255, 174, 69)!important;
    color: white!important;
    border-bottom: 2px solid rgb(255, 174, 69)!important;
    border-top: 2px solid rgb(255, 174, 69)!important;
}

.contractEditHeader{ 
    color: white;
    min-height: 170px;
}

.salesRepStyle{
    width: 100px;
}

.contractLineageButtonContainer {
    margin-top: 45px;
    text-align: center!important;
}

.contractLineageButton{
    font-size: 50px!important;
    text-align: center!important;
}

.childParentContractCount {
    font-size: 16px;
    margin-top: -30px!important;
}


.addAndDeleteSpan>i{
    display: flex!important;
    flex: 2!important;
    justify-content: space-around!important;
}

.feeTotals{
    font-weight: 700;
}

.contractDates {
    display: flex;
    justify-content: center;
    flex: 3;
}

.dateMargins {
    margin-left: 20px;
    margin-right: 20px;
}

.dateLabels{
    margin-top: -20px!important;
    margin-bottom: 15px!important;
}

.contractStatus{
    font-size: 22px;
    margin-top: -8px;
    text-align: center!important;
    font-weight: 700;
    text-shadow: -1px -1px 0 rgb(133, 133, 133), 1px 1px 0 rgb(206, 206, 206), 1px 1px 0 rgb(167, 167, 167), 1px 1px 0 rgb(255, 255, 255);
}

.highlightRate{
    background-color: yellow;
}

.specialRestrictionCol{
    max-width: 500px;
}

.demurrageWidth{ 
    max-width: 900px!important;
}

.demurrageCombo {

    display: flex;
    justify-content: space-around;
    max-width: 300px;
}

.freetimePortWidth {
    max-width: 150px;
}

.chargeSumSwitch {
    float: right!important;
    margin-right: 50px!important;
}

th {
    text-align: left;
}

.modalAddOriginRow {
    margin-top: 30px!important;
}

.contractEditCheckBox[type="checkbox"].filled-in:checked + span:not(.lever):after {
    border: 2px solid rgb(255, 255, 255);
    background-color:rgb(0, 0, 255);
    color: white;
}

.contractEditCheckBox {
    color: white!important;
}