.div {
    margin: 0px 0px 12px;
    padding: 0px 12px 0px 0px !important;
    position: relative;
    display: flex;
    justify-content: center;
}

.button {
    margin: 0px;
    padding: 0px 0px 0px 6px;
    border: none;
    border-radius: 3px !important;
    height: 30px;
    font-size: .75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    float: left;
    width: 100%;
}



.icon {
    font-size: 24px;
    margin-left: 4px;
    max-width: 24px;
    float: left;
}

.round {
    border-radius: 18px;
}

.filled {
    box-shadow: 0px 2px 2px #888;
    background-color: #eee;
}

.outline {
    background-color: transparent;
    border: 1px solid black;
    box-shadow: 0px 2px 2px #ccc;
}

.outline.black {
    color: black;
    background-color: white;
    border: 1px solid black;

}

.black:hover,
.black:focus {
    color: white;
    background-color: black;
    box-shadow: 0px 2px 2px #ccc;
}

.flat {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.orange {
    color: #e65100;
    background-color: white;
}

.orange:hover,
.orange:focus {
    color: white;
    background-color: #e65100;
    box-shadow: 0px 2px 2px #ccc;
}

.outline.orange {
    color: #e65100;
    background-color: white;
    border: 1px solid #e65100;
}

.outline.orange:hover,
.outline.orange:focus {
    color: white;
    background-color: #e65100;
    border: 1px solid white;
}

.blue {
    color: #2196f3;
    background-color: white;
}

.blue:hover,
.blue:focus {
    color: white;
    background-color: #2196f3;
    box-shadow: 0px 2px 2px #ccc;
}

.outline.blue {
    color: #2196f3;
    background-color: white;
    border: 1px solid #2196f3;
    /* border: none; */
}

.outline.blue:hover,
.outline.blue:focus {
    color: white;
    background-color: #2196f3;
    border: 1px solid white;
}

.filledBlue {
    color: white;
    background-color: #2196f3;
}

.filledBlue:hover,
.filledBlue:focus {
    color: #2196f3;
    background-color: white;
    box-shadow: 0px 2px 2px #ccc;
}

.outline.filledBlue {
    color: white;
    background-color: #2196f3;
    border: 1px solid #2196f3;
}

.outline.filledBlue:hover,
.outline.filledBlue:focus {
    color: #2196f3;
    background-color: white;
    border: 1px solid #2196f3;
}

.filledGreen {
    color: white;
    background-color: #4caf50;
}

.filledGreen:hover,
.filledGreen:focus {
    color: #4caf50;
    background-color: white;
    box-shadow: 0px 2px 2px #ccc;
}

.outline.filledGreen {
    color: white;
    background-color: #4caf50;
    border: 1px solid #4caf50;
}

.outline.filledGreen:hover,
.outline.filledGreen:focus {
    color: #4caf50;
    background-color: white;
    border: 1px solid #4caf50;
}

.filledBlack {
    color: white;
    background-color: black;
}

.filledBlack:hover,
.filledBlack:focus {
    color: black;
    background-color: white;
    box-shadow: 0px 2px 2px #ccc;
}

.outline.filledBlack {
    color: white;
    background-color: black;
    border: 1px solid black;
}

.outline.filledBlack:hover,
.outline.filledBlack:focus {
    color: black;
    background-color: white;
    border: 1px solid black;
}

.red {
    color: white;
    background-color: #f44336;
}

.red:hover,
.red:focus {
    color: #f44336;
    background-color: white;
}

.outline.red {
    color: #f44336;
    background-color: white;
    border: 1px solid #f44336;
}

.outline.red:hover,
.outline.red:focus {
    color: white;
    background-color: #f44336;
    border: 1px solid white;
}

.filledRed {
    color: white;
    background-color: #ff0000;
}

.filledRed:hover,
.filledRed:focus {
    color: #ff0000;
    background-color: white;
}

.outline.filledRed {
    color: white;
    background-color: #ff0000;
    border: 1px solid white;
}

.outline.filledRed:hover,
.outline.filledRed:focus {
    color: rgb(255, 0, 0);
    background-color: #ffffff;
    border: 1px solid rgb(255, 0, 0);
}

.white {
    color: white;
    background-color: #333;
}

.white:hover,
.white:focus {
    color: #333;
    background-color: white;
}

.outline.white {
    color: #333;
    background-color: white;
    border: 1px solid #333;
}

.outline.white:hover,
.outline.white:focus {
    color: white;
    background-color: #333;
    border: 1px solid white;
}

.green {
    color: #4caf50;
    background-color: white;
}

.green:hover,
.green:focus {
    color: white;
    background-color: #4caf50;
}

.outline.green {
    color: #4caf50;
    background-color: white;
    border: 1px solid #4caf50;
    /* border: none; */
}

.outline.green:hover,
.outline.green:focus {
    color: white;
    background-color: #4caf50;
    border: 1px solid white;
}

.gray {
    color: #9e9e9e;
    background-color: white;
}

.gray:hover,
.gray:focus {
    color: white;
    background-color: #9e9e9e;
}

.outline.gray {
    color: #9e9e9e;
    background-color: white;
    border: 1px solid #9e9e9e;
}

.outline.gray:hover,
.outline.gray:focus {
    color: white;
    background-color: #9e9e9e;
    border: 1px solid white;
}

.filledGray {
    color: white;
    background-color: #9e9e9e;
}

.filledGray:hover,
.filledGray:focus {
    color: #9e9e9e;
    background-color: white;
}

.outline.filledGray {
    color: white;
    background-color: #9e9e9e;
    border: 1px solid white;
}

.outline.specialGray {
    color: #9e9e9e;
    background-color: white;
    border: #9e9e9e;
}

.disabled {
    background-color: #ddd !important;
    color: #777 !important;
    border: #777 !important;
    box-shadow: none !important;
    cursor: not-allowed;
}

.disabled>i {
    cursor: not-allowed;
}

.address {
    float: left;
    margin-top: 50px;
}

.modalFormat {
    margin-top: 30px;
    text-align: center;
}

.removeMargin {
    margin-bottom: 0px;
}

.commercialInvoiceButtons {
    margin-bottom: 0px;
    text-align: center;
    margin-left: -20px !important;
    margin-right: 40px;
}

.cooButton {
    margin-bottom: 0px;
    margin-top: 10px;
}

.toolTip:hover {
    position: relative;
}

.toolTip .toolTipText {
    visibility: hidden;
    background-color: rgb(0, 0, 0);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 4000 !important;
    border-radius: 15px 0px 15px 15px;
}

.toolTip:hover .toolTipText {
    visibility: visible;
}