.logo-img {
    height: 30px;
    position: relative;
    top: 5px;
    left: 2px;
    margin-right: 4px;
}

.logo-img-select{
    height: 30px;
    position: relative;
    top: 5px;
    left: -9px;
    margin-right: 4px;
    margin-top: -11px !important;
}

.brand-logo{
    margin-top: -18px !important;
}

nav{
    height: 35px;
}

.lf-shipper-select{
    color: blue !important;
}

.lf-nav-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 35px !important;
}

.select-wrapper {
    position: relative;
    height: 99%;
    width: 400px;
}

.select-wrapper .caret {
    position: absolute;
    fill: #ff3d00 !important;
}

.lf-nav-div>.select-wrapper>.select-dropdown.dropdown-trigger {
    font-weight: 400;
    font-size: 1.8em;
    font-style: italic;
    width: 100%;
    text-align: start;
    border: none;
    padding-left: 12px;
    color: #ff3d00;
    margin-top: -5px !important;
}

.lf-nav-div>.select-wrapper>.dropdown-content[style]{
    position:  relative !important;
    top: -70px;
    height: 40vh;
}

.lf-nav-div>.select-wrapper>.dropdown-content>li{
    margin: 0px;
    padding: 0px;
    min-height: 30px;
}

.lf-nav-div>.select-wrapper>.dropdown-content>li>span{
    color: black !important;
    border-bottom: #ddd 1px dotted;
    margin: 0px;
    padding: 4px;
}