.div {
    margin: 0px;
    padding: 0px 0px 0px 0px!important;
    position: relative;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
}



.input {
    border: #9e9e9e solid 1px!important;
    border-radius: 3px;
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 6px!important;
    background-color: white;
    height: 30px;
    outline: none;
    font-size: 1.15rem;
    width: 100%;
}

.div > div > div > input {
 border: #9e9e9e solid 1px!important;
}

.div > div > div > div {
    padding-left: 0px!important;
}

.div > div > div {
    padding-left: 0px!important;
}

.div > div {
    padding-right: 12px;
}


.left{
    border-right: none!important;
}

.right{
    border-left: none;
}

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.msg {
    position: absolute;
    color: #f44336;
    font-size: .8em;
    bottom: -14px;
    left: 3px;
}

.disabled{
    background-color: #eee;
}

.label.disabled{
    display: none;
}

.popperClassName {
    z-index: 100;
}