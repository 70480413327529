.div {
    margin: 0px!important;
    margin-top: 0px!important;
    margin-right: 0px!important;
    padding-right: 0px!important;
    position: relative;
}

.select {
    position: relative;
    margin: 0px;
    padding: 0px 0px 0px 0px;
    background-color: white;
    border: #9e9e9e solid 1px;
    border-radius: 3px;
    height: 22px;
    outline: none;
    font-size: .8rem;
    width: 100%;
}

.select:hover {
    border: solid 1px black;
}

.select:focus {
    outline: 1px solid #2196f3!important;
    box-shadow:0px 0px 0px 1px #2196f3 inset;  
    padding: 0px 0px 0px 0px;
}

.select.err {
    border: 1px solid #f44336;
}

.select.err:focus {
    border: solid 2px #f44336;
    padding: 0px 0px 0px 4px;
}

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    height: 13px;
    background-color: white;
    font-size: .6rem;
}

.select option {
    font-size: 1rem;
}

.NEW {
    font-weight: 600;
}

.REQUESTED {
    color: purple;
    font-weight: 600;
}

.CONFIRMED {
    color: green;
    font-weight: 600;
}

.DECLINED {
    color: red;
    font-weight: 600;
}

.NVA {
    color: orange;
    font-weight: 600;
}