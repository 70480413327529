.noMarginBottom {
    margin-bottom: 0px;
}

.servicesTable {
    font-size: 12px;
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    display: inline-block;
    text-align: left;
}

.servicesTable>tr>td {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}

.carrierLabel {
    height: 10px;
}

.leftTable {
    border-right: 1px solid black;
}

.loadingSpinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.tableDisplay {
    display: flex;
    flex-direction: flex-start !important;
}

.yangMingResultsDisplay:nth-child(odd) {
    background-color: rgb(213, 213, 213);
}

.yangMingResultsDisplay:nth-child(even) {
    /* background-color: #eaeaea; */
}

.serviceTableHeader {
    background-color: #33679e;
    color: white;
}

.locationCount {
    color: rgb(142, 142, 142);
}

.shipperCount {
    color: black;
}

.additionalText {
    color: red;
}

.carrierField>select,
.carrierField>input {
    border: 2px solid #33679e !important;
}