.margin {
    margin: auto;
    padding: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 100px;
    max-width: 800px;
    border-radius: 20px;
    text-align: center;
}  

.label {
    margin: 0px;
    padding: 0px 2px;
    color: #9e9e9e;
    position: absolute;
    left: 4px;
    top: -8px;
    background-color: white;
}

.body{
    margin-top: 20px;
    padding: 20px;
}

.buttons {
    color: blue;
    justify-content: right;
    margin-right:5px;
    margin-left:5px;
}

.edit{
    color:green;
    margin-left: 20px;
}

.delete{
    color:red;
}

.cardStyle{
    min-height: 150px;
    min-width: 250px;
    float: left;
    margin-right: 10px;
    margin-left: 0px;
    margin-bottom: 0px;
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 0px;
    border: 1px whitesmoke dotted;
}

.modalCardStyle{
    min-height: 100px;
    min-width: 200px;
    float: left;
    margin-right: 10px;
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 10px;
    cursor: pointer;
}