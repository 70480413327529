.overviewCard {
    height: 60px;

}

.contractCard {
    background-color: #33679e !important;
    color: white;
}

.contractModal {
    height: 83% !important;
    max-height: 100% !important;
    width: 99%;
    overflow: auto !important;
}

.modalStepButtons {
    width: 100% !important
}

.modalStepButtonsLeft {
    bottom: -45px;
    left: 100px;
    float: left !important;
}

.modalStepButtonsRight {
    bottom: 0px;
    right: 100px;
    float: right !important;
}

.contractsTbody {
    position: relative;
    /* display: inline-block;
    text-align: left; */
    height: 200px;
}

.contractLoadingSpinner {
    margin-top: 20%;
    height: 300px !important;
    width: 300px !important;
}

.transparentSpinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 500;
    background-color: rgb(120, 120, 120, .2);
}