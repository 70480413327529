.toast {
    background-color: #444;
    border: solid 1px;
    color: #2196f3;
    border-color: #2196f3;
    border-radius: 3px;
}

.warning{
    border-color: #ff9800;
    color: #ff9800
}

.error{
    border-color: #f44336;
    color: #f44336;
}

.success{
    border-color: #00e676;
    color: #00e676;
}