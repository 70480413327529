@keyframes progressBar {
    100% {
        background-position: 0% 0%;
    }
    0% {
        background-position: 95% 95%;
    }
}

/* .NEW { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .NEW > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      rgb(75, 145, 75) 1rem,
      rgb(75, 145, 75) 2rem
    );
    background-color: rgb(37, 228, 37)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */



/* .PENDING { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .PENDING > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    animation: progressBar 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .UPDATING { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .UPDATING > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #329ef4 1rem,
      #329ef4 2rem
    );
    background-color: rgb(0, 0, 255)!important;
    background-size: 200% 200%;
    animation: progressBar 10s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .DECLINED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
} */

/* .DECLINED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem,
      transparent 1.7rem,
      rgb(201, 55, 55) 1rem,
      rgb(201, 55, 55) 2rem
    );
    background-color: #f44336!important;
    background-size: 200% 200%;
    animation: progressBar 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */


/* .COMPLETED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.COMPLETED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(211, 211, 211) 1rem,
      rgb(211, 211, 211) 2rem
    );
    background-color: rgb(130, 130, 130)!important;
    background-size: 200% 200%;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .CLOSED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.CLOSED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -120deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(120, 120, 120) 1rem,
      rgb(120, 120, 120) 2rem
    );
    background-color: rgb(0, 0, 0)!important;
    background-size: 200% 200%;
    animation: progressBar 100s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */

/* .REQUESTED { 
    height: 16px!important;
    position: relative;
    background: #e0dbcd!important;
    overflow: hidden;
    box-shadow:5px 5px 9px 0px rgba(0,0,0,0.23);
}

.REQUESTED > .determinate {
    background-image: 
    repeating-linear-gradient(
      -45deg, 
      transparent 1rem, 
      transparent 1.7rem,
      rgb(177, 138, 69) 1rem,
      rgb(177, 138, 69) 2rem
    );
    background-color: rgb(255, 140, 0)!important;
    background-size: 200% 200%;
    animation: progressBar 20s infinite linear;
    margin-top: -5px;
    color: white!important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    text-align:center;
    border-radius: 10px;
    box-shadow:5px 5px 16px 0px rgba(0,0,0,0.44);
    border: 1px solid rgb(193, 193, 193)!important;
    text-shadow: 0 0 5px rgb(0, 0, 0);
} */


.newRequestEmployee {
    background-color: #FFE388;
    animation-name: employeeFirework;
    animation-duration: 1s;
  }

  .newRequestCustomer {
    background-color: white;
    animation-name: customerFirework;
    animation-duration: 1s;
  }

  .isDateRangeCheckBox, .isDateRangeCheckBox:hover {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .isDateRangeCheckBox > div > p > label,   .isDateRangeCheckBox:hover > div > p > label {
    width: 70px;
  }

  .isDateRangeCheckBox > div, .isDateRangeCheckBox:hover > div {
    width: 70px;
  }

  .isDateRangeCheckBox > div > p, .isDateRangeCheckBox:hover > div > p {
    width: 70px!important;
  }

  .resultsDisplay.menu-selected {
    color: white;
    background-color: rgb(255, 174, 69);
    border-left: 1px rgb(255, 174, 69) solid;
  }

  .resultsDisplay.menu-selected > td {
    border-bottom: 1px white solid!important;
    border-left: 1px rgb(255, 174, 69) solid!important;
    border-right: 1px rgb(255, 174, 69) solid!important;

  }
  
  @keyframes employeeFirework {
    0%   {
      background-color:#33679E;
      color: white;
    }
    5%{
      background-color:#46729C;
      color: #eff4f9;
    }
    15%   {
      background-color: #587E9A;
      color: #d9e5f1;
    }
    25%{
      background-color:#6B8998;
      color: #c3d8ef;
    }
    35%   {
      background-color: #7D9496;
      color: #a8c8eb;
    }
    45%{
      background-color:#909F94;
      color: #76a6d8
    }
    55%{
      background-color: #A2AB92;
      color: #5287bf;
    }
    65%{
      background-color:#B5B690;
      color: #457ab3;
    }
    75%{
      background-color: #C7C18E;
      color: #33679e;
    }
    85%{
      background-color:#DACC8C;
      color: black;
    }
    95%   {
      background-color: #ECD88A;
      color: black;
    }
    100%   {
      background-color: #FFE388;
      color: black;
    }
  }

  @keyframes customerFirework {
    0%   {
      background-color:#33679E;
      color: white;
    }
    5%{
      background-color:#4675A7;
      color: #eff4f9;
    }
    15%   {
      background-color: #5883B0;
      color: #d9e5f1;
    }
    25%{
      background-color:#6B90B8;
      color: #c3d8ef;
    }
    35%   {
      background-color: #7D9EC1;
      color: #a8c8eb;
    }
    45%{
      background-color:#90ACCA;
      color: #76a6d8
    }
    55%{
      background-color: #A2BAD3;
      color: #5287bf;
    }
    65%{
      background-color:#B5C8DC;
      color: #457ab3;
    }
    75%{
      background-color: #C7D6E5;
      color: #33679e;
    }
    85%{
      background-color:#DAE3ED;
      color: black;
    }
    95%   {
      background-color: #ECF1F6;
      color: black;
    }
    100%   {
      background-color: #FFFFFF;
      color: black;
    }
  }
  
  .preload * {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
  }

  .lf-portal-table-requests {
    background-color: white;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
  }