*{
    margin: 0px;
    padding: 0px;
}

ul{
    border-bottom: 1px gray solid;
}

button{
    color: #2196f3;
    margin: 12px;
    padding: 8px;
    font-size: 1em;
    background-color: white;
}

button:hover{
    background-color: #eee;
}

button.active{
    border-bottom: #2196f3 2px solid;
}

button:focus{
    color: white;
    background-color: #2196f3;
}