.standingListModal {
    width: 80%;
    overflow-y: scroll;
}

.standingListModal>tr>td {
    white-space: pre;
}

.standingListTable {
    display: inline-block;
    margin-bottom: 10px;
}

.resultsContainer {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    -webkit-justify-content: flex-start !important;
}

.weekListContainer {
    display: flex !important;
    flex-direction: column;
}

.weekListTable {
    display: inline-block;
    width: inherit !important;
}

.weekListTableEdit {
    display: block;
    width: 1250px !important;
    max-width: 1250px !important;
    min-width: 1250px !important;
}

.weekListTable>thead {
    background-color: #33679e;
    color: white;
}

.weekListTable>thead>tr>th {
    border-right: 1px solid white;
    font-size: 12px;
}

.weekListTbody>tr>td {
    padding: 0px 4px 0px 4px;
    border: 1px solid grey;
    font-size: 12px;
    white-space: pre !important;
}

.editTableRow>td:not(.containerCountAndTypeCol):not(.assigneeCell):not(.requestNameCell) {
    padding: 0px !important;
    padding-bottom: 0px !important;
    margin: 0px !important;
    height: 22px !important;
    min-height: 22px !important;
    max-height: 22px !important;
}

.editTableRow {
    line-height: 3px !important;
    border: 1px solid red !important;
}

.weekListTbody>tr:nth-child(even) {
    background-color: rgb(225, 225, 225);
}

.weekListTbody>tr:nth-child(even).menu-selected {
    background-color: rgb(210, 12, 12);
}

.iconColumn {
    padding-top: 4px !important;
}

.iconColumn>div>i,
.iconColumn>div>div>i {
    display: flex;
    justify-content: center;
    font-size: 18px;
}

.createOption {
    cursor: pointer;

}

.containerCountAndTypeCol {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    display: grid;
    grid-template-columns: 50% 50%;
    border: none !important;
}

.requestNameCell,
.assigneeCell {
    padding-left: 4px !important;
}

.NEW {
    font-weight: 600;
    cursor: pointer;
}

.REQUESTED {
    color: purple;
    font-weight: 600;
    cursor: pointer;
}

.CONFIRMED {
    color: green;
    font-weight: 600;
    cursor: pointer;
}

.DECLINED {
    color: red;
    font-weight: 600;
    cursor: pointer;
}

.NVA {
    color: rgb(255, 174, 69);
    font-weight: 600;
    cursor: pointer;
}

.DELETED {
    color: blue;
    font-weight: 600;
    cursor: pointer;
}

.saveIcon {
    margin-top: 4px;
    font-size: 18px
}

.weekListCount {
    position: absolute;
    bottom: 10px;
    left: 48%;
    z-index: 1;
}

.standingListCount {
    z-index: 1;
}

.standingListCountContainer {
    text-align: center;
    margin-bottom: 0px;
}

.outlineRequestIndex {
    box-shadow: inset 0px 0px 0px 0px rgb(0, 0, 0) !important;
}

.outlineRequestIndex:focus {
    margin-left: -5px;
    border-radius: 5px;
    outline: solid #2196f3 !important;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}

.weekNumber {
    text-align: center;
    font-weight: 600;
    font-size: 17px;
}