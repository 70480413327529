.div {
    margin: 0px;
    padding: 0px 6px 0px 0px!important;
    position: relative;
}

.innerDiv {
    height: 16px;
    width: 100%;
    padding: 0px 0px;
    margin-left: -10px;
    border-radius: 4px;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 20px;
}

.innerDiv:hover {
    width: 100%;
}

.span{
    font-size: 17px !important;
    padding-left: 30px !important;
}

.span:hover{
    background-color: rgb(212,212,212);

}


.p {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 8px;
}

.span:hover{
        background-color: rgb(212, 212, 212);
}

.label{
    transform: scale(.6)
}

input{
    will-change: transform;
}
